<template>
  <div class="time-setting">
    <div>
      <custom-select :label="$t('times.labels.timeLength')"
                     :classes="['w-full']"
                     styles="lineHeight: 35px; marginTop: 10px;"
                     :default="club.timeLength"
                     :options="timeLengthOptions"
                     v-model="club.timeLength"
      />

      <div class="time-picker">
        <div class="date-picker-item">
          <custom-validate-input :label="$t('times.labels.startTime')"
                                 :placeholder="'07:00'"
                                 :classes="{'w-full': true}"
                                 :regex="timeRegex"
                                 v-model="club.startTime"/>
          <date-picker type="time" v-model="club.startTime.value" :max="getStartTimeMax"/>
        </div>

        <div class="date-picker-item">
          <custom-validate-input :label="$t('times.labels.endTime')"
                                 :placeholder="'23:00'"
                                 :classes="{'w-full': true}"
                                 :regex="timeRegex"
                                 v-model="club.endTime"/>
          <date-picker type="time" v-model="club.endTime.value" :min="club.startTime.value"/>
        </div>
      </div>
    </div>

    <vs-button v-show="false"
               id="editTimeSettingBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import moment from 'moment'
  import {getClubSetting, editClubSetting} from '../../../../http/requests/setting'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomSelect from "../../../../components/customSelect/customSelect";
  import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
  import CustomValidateInput from "../../../../components/customInput/customValidateInput";
  import CustomNumberInput from "../../../../components/customInput/customNumberInput";
  import {checkUserPermissions} from "../../../../assets/js/functions";
  import {getTimes} from "../../../../http/requests/times";
  import axios from "axios";

  export default {
    name: "insertTime",
    components: {CustomNumberInput, CustomValidateInput, CustomValidateTextarea, CustomSelect, DatePicker},
    data() {
      return {
        timeRegex: this.$validator('regex.time'),
        timeLengthOptions: [
          {
            id: 1,
            label: `60 ${this.$t('times.labels.minute')}`,
            value: '60'
          },
          {
            id: 2,
            label: `90 ${this.$t('times.labels.minute')}`,
            value: '90'
          }
        ],
        club: {
          timeLength: {
            id: 1,
            label: `60 ${this.$t('times.labels.minute')}`,
            value: '60',
            isValid: true
          },
          startTime: {
            value: '',
            isValid: true
          },
          endTime: {
            value: '',
            isValid: true
          }
        }
      }
    },

    computed: {
      getStartTimeMax () {
        if (this.club.endTime.value !== '00:00' && this.club.endTime.value !== '24:00') {
          return this.club.endTime.value
        } else {
          return ''
        }
      }
    },

    created() {
      this.getSetting()
    },

    methods: {
      getSetting() {
        getClubSetting().then((response) => {
          const club = response.data.data
          this.club.timeLength.value = club.time_length || ''
          this.club.timeLength.label = club.time_length ? `${club.time_length} ${this.$t('times.labels.minute')} ` : '-'
          this.club.startTime.value = club.club_start_time || ''
          this.club.endTime.value = club.club_end_time || ''

        })

      },

      sendData() {
        if (this.club.timeLength.value &&
          this.club.startTime.isValid &&
          this.club.endTime.isValid) {
          const timeDuration = moment.duration(this.club.endTime.value).asMinutes() - moment.duration(this.club.startTime.value).asMinutes()
          if (timeDuration % parseInt(this.club.timeLength.value) === 0) {
            const payload = {
              time_length: this.club.timeLength.value,
              club_start_time: this.club.startTime.value,
              club_end_time: this.club.endTime.value
            }
            editClubSetting(payload).then(response => {
              this.$vs.notify({
                time: 2400,
                title: this.$t('alert.message.title'),
                text: this.$t('times.notifications.edit.success'),
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'success'
              })

              this.$emit('edit')
            }).catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  time: 2400,
                  title: this.$t('alert.error.title'),
                  text: error.response.data.message || error.response.data.error,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              }
            })
          } else {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.error.title'),
              text: this.$t('times.validators.timeLength'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }

        }
      }
    }
  }
</script>

<style lang="scss">
  .time-setting {
    padding: 10px;
    padding-top: 0;
    min-height: 200px;

    .time-picker {
      display: flex;
      justify-content: space-between;

      .date-picker-item {
        border: 0 !important;
        border-radius: 0.4rem;
        position: relative;
        flex-grow: 0.5;
        max-width: 48%;

        .custom-input {
          margin: 0;
        }

        .date-picker-label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 5px;
          padding: 0 5px;
          background-color: #ffffff;
          z-index: 1;
        }

        .vpd-input-group {
          width: 20px !important;
          position: absolute;
          top: 6px;
          left: 2px;

          .vpd-icon-btn {
            opacity: 1 !important;
            height: 25px !important;
            width: 20px !important;
            background-color: inherit !important;

            svg {
              fill: #000000;
              overflow: visible !important;
            }
          }

          .form-control {
            border: 0;
            width: 100%;
            display: none;
            line-height: 35px;
          }
        }
      }
    }
  }
</style>
